<template>
  <b-row>
    <b-col cols="12">
      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-2">
          <b-row class="align-items-center justify-content-between">
            <b-col
              cols="1"
            >
              <label>Показывать по</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>записей</label>
            </b-col>

            <b-col
              class="d-flex"
              xl="5"
              lg="8"
              md="9"
              sm="8"
            >
              <v-select
                v-model="byStatusId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="payoutStatusList"
                class="w-100"
                :reduce="val => val.id"
                placeholder="Просмотр по статусу"
                title="Просмотр по статусу"
                @input="byStatusId, refetchData()"
              />

              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1 ml-1"
                placeholder="Поиск..."
                title="Поиск..."
              />
            </b-col>
          </b-row>
        </div>
        <b-table
          ref="refPayout"
          class="position-relative"
          :items="fetchItems"
          responsive
          :fields="Columns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="Совпадающих записей не найдено"
          :sort-desc.sync="isSortDirDesc"
        >

          <template #cell(userName)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.file ? data.item.file.path : null"
                  :text="avatarText(`${ data.item.firstName } ${ data.item.lastName || '' }`)"
                  :variant="`light-${generateRandomVariant()}`"
                  :to="{ name: 'payout-view', params: { id: data.item.userId, payoutId: data.item.id } }"
                />
              </template>
              <b-link
                :to="{ name: 'payout-view', params: { id: data.item.userId, payoutId: data.item.id } }"
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.firstName }} {{ data.item.lastName || '' }}
              </b-link>
              <small class="text-muted">{{ data.item.phone }}</small>
            </b-media>
          </template>

          <template #cell(bonus)="data">
            <div class="text-nowrap">
              <feather-icon
                icon="AwardIcon"
                size="18"
                class="mr-50"
              />
              <span class="align-text-top">{{ data.value }}</span>
            </div>
          </template>

          <template #cell(payoutStatus)="data">
            <b-badge :variant="`light-${ resolvePayoutStatusVarian(data.value.name) }`">
              {{ resolveUserPayoutMotivationStatus(data.value.name) }}
            </b-badge>
          </template>

          <template #cell(createdAt)="data">
            {{ formatDate(data.value) }}
          </template>
          <template #cell(updatedAt)="data">
            {{ formatDate(data.value) }}
          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Показано с {{ dataMeta.from }} по {{ dataMeta.to }} из {{ dataMeta.of }} элементов</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCard, BTable, BFormInput, BPagination, BMedia, BAvatar, BLink, BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import { formatDate } from '@/libs/helper'
import { ref } from '@vue/composition-api'
import {
  generateRandomVariant, resolveUserMotivationChanged, verifEmailVariant, resolvePayoutStatusVarian, resolveUserPayoutMotivationStatus,
} from '@/utils/UserFilter'
import { payoutUsersList, getPayoutStatusList } from '@/application/payoutService'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BFormInput,
    BPagination,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    vSelect,
  },

  setup() {
    const {
      refPayout,
      dataMeta,
      fetchItems,
      Columns,
      perPage,
      totalItems,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refetchData,

      processedFilter,
      byStatusId,
      processedOptions,
      statusOptions,
    } = payoutUsersList()

    const payoutStatusList = ref()

    getPayoutStatusList().then(res => {
      payoutStatusList.value = res
    })
    return {
      refPayout,
      Columns,
      dataMeta,
      fetchItems,
      payoutStatusList,

      perPage,
      totalItems,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refetchData,

      processedFilter,
      byStatusId,
      processedOptions,
      statusOptions,

      avatarText,
      formatDate,

      generateRandomVariant,
      resolveUserMotivationChanged,
      verifEmailVariant,
      resolvePayoutStatusVarian,
      resolveUserPayoutMotivationStatus,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
